import React, { useEffect } from "react"
import * as styles from "./privacy_policy.module.css"
import Seo from "../components/seo"
import { Link } from "gatsby"


const sidep_link_style = {
	color: "#175C7B",
	fontWeight: "bold",
	textDecoration: "none",
}


const MainP = () => {
	return (
		<>
		<div style={{marginTop:"50px"}}>
			<h1>Privacy Policy</h1>
			<p>
				This Privacy Policy describes how your personal information is processed when you visit
				<Link to="/" style={sidep_link_style}> https://fumetteriacentrodelmondo.com/</Link> (the “Site”).
		    </p>

			<h3>PERSONAL INFORMATION WE COLLECT</h3>
			<p>
				When you contact us via email,
				we collect your address as well as any contact information present in the missive.
				We refer to this voluntarily sent information as “User Information”.
			</p>

			<p>
				When we talk about “Personal Information” in this Privacy Policy,
				we are talking about User Information.
			</p>

			<h3>HOW DO WE USE YOUR PERSONAL INFORMATION?</h3>
			<p>
				We use the Personal Information solely to address and reply to your requests.
			</p>

			<h3>SHARING YOUR PERSONAL INFORMATION</h3>
			<p>
				We may share your Personal Information to comply with applicable laws
				and regulations, to respond to a subpoena, search warrant or
				other lawful request for information we receive, or to otherwise protect our rights.
			</p>

			<h3>DO NOT TRACK</h3>
			<p>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>

			<h3>YOUR RIGHTS</h3>
			<p> If you are a European resident, you have the right to access personal information
				we hold about you and to ask that your personal information be corrected, updated, or deleted.
				If you would like to exercise this right, please contact us through the contact information below.
				Additionally, if you are a European resident we note that we are processing your information
				in order to fulfill contracts we might have with you (for example if you make an order through the Site),
				or otherwise to pursue our legitimate business interests listed above.
				Additionally, please note that your information will be transferred outside of Europe,
				including to Canada and the United States.
			</p>

			<h3>DATA RETENTION</h3>
			<p>
				When you contact us, we will maintain your Personal Information for our records unless and until
				you ask us to delete this information.
			</p>

			<h3>MINORS</h3>
			<p>
				The Site is not intended for individuals under the age of 13.
			</p>

			<h3>CHANGES</h3>
			<p>
				We may update this privacy policy from time to time in order to reflect,
				for example, changes to our practices or for other operational, legal or regulatory reasons.
			</p>

			<h3>CONTACT US</h3>
			<p>
				For more information about our privacy practices, if you have questions,
				or if you would like to make a complaint, please contact us by e-mail at
				<a href="mailto:fumetteriacentrodelmondo@outlook.it" target="_blank" rel="noopener noreferrer" style={sidep_link_style}> fumetteriacentrodelmondo@outlook.it</a>
			</p>
		</div>
		</>

	)
}


const PrivacyPolicyPage = () => {
	useEffect( () => {
		if( typeof window !== "undefined" )
			window.scrollTo( 0, 0 )
	}, [])

	return (
		<>
			<Seo title="Home"/>
			<div className={styles.pageWrapper}>
				<div className={styles.grid}>
					<div className={styles.cellitem}>
						{<MainP/>}
					</div>

					<div className={styles.cellitem + " " + styles.backLink_wrap}>
						<Link to="/" className={styles.backLink}>BACK TO HOME PAGE</Link>
					</div>
				</div>
			</div>
		</>
	)
}


export default PrivacyPolicyPage
